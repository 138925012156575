import React, { useState, useMemo, useEffect } from "react";
import Link from "next/link";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Meta from "../components/Meta";
import { Metamask_comp_login } from "../components/metamask/Metamask";
import { useAppDispatch, useAppState } from "../components/AppContext";
import jwt_decode from "jwt-decode";
import { useRouter } from "next/router";
import PublicRoute from "../components/PublicRoute";
import { toast } from "react-toastify";

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const RE_DIGIT = new RegExp(/^\d+$/);
const PasscodeInput = ({ value, valueLength, onChange }) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    const nextInputEl = target.nextElementSibling;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };
  const inputOnKeyDown = (e) => {
    const { key } = e;
    const target = e.target;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);
  };
  const inputOnFocus = (e) => {
    const { target } = e;
    const prevInputEl = target.previousElementSibling;
    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div id="inputs">
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className="digit text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-lg border py-[0.6875rem] px-4 bg-white"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  );
};

const RequestOTPComp = ({ setItemActive, setSecSinceLastReq }) => {
  const [email, setEmail] = useState("");
  const [rockLax, setRockLax] = useState(true);
  const state = useAppState();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      router.query?.poet &&
      process.env.NEXT_PUBLIC_POET_ROCKLAZ_ACTIVE == "false"
    ) {
      setRockLax(false);
    }
  }, []);

  const requestOtpHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const resp = await fetch(`${BASE_URL}v1/public/user/request-otp`, {
        method: "POST",
        body: JSON.stringify({ email: email, exp: router.query?.poet }),
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.NEXT_PUBLIC_POET_API_KEY,
        },
      });
      const jsonRes = await resp.json();
      if (jsonRes.success) {
        toast.success(jsonRes.message);
        setItemActive("1");
        setSecSinceLastReq();
        router.push(`?email=${email}&poet=${router.query?.poet}`);
        dispatch({
          email: email,
        });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(jsonRes.message);
      }
    } catch (e) {
      setError("Something went wrong.");
      setLoading(false);
    }
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <form className="auth-form" onSubmit={requestOtpHandler}>
        <input
          onChange={onChangeHandler}
          value={email}
          type="email"
          placeholder="Enter your email"
          className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-lg border py-[0.6875rem] py-4 px-6 bg-white"
        />
        <button
          // htmlType="submit"
          disabled={loading}
          className="dark:bg-jacarta-700 mt-4 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
        >
          {loading ? (
            <span className="hover:swiper-lazy-preloader-hover swiper-lazy-preloader btn-loader"></span>
          ) : (
            <span>Sign in / Register</span>
          )}
        </button>
      </form>
    </>
  );
};

const Passcode = ({ setItemActive, secSinceLastReq, intervalHanler }) => {
  const state = useAppState();
  const [otp, setOtp] = useState("");
  const onChange = (value) => setOtp(value);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");



  useEffect(() => {
    if (otp.trim().length == 6) {
      verifyHandler();
    }
  }, [otp]);

  const verifyHandler = async (e) => {
    e?.preventDefault();
    if (otp.length == 6) {
      setError("");
      setLoading(true);
      try {
        const email = router.query?.email;
        const resp = await fetch(`${BASE_URL}v1/public/user/verify-otp`, {
          method: "POST",
          body: JSON.stringify({
            email: email,
            otp: otp,
            poet: router.query?.poet,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonRes = await resp.json();
        if (jsonRes.success) {
          toast.success(jsonRes.message);
          localStorage.setItem("access_token", jsonRes.access_token);
          var decoded = jwt_decode(jsonRes.access_token);
          const { exp } = decoded;
          if (exp < (new Date().getTime() + 1) / 1000) {
            return false;
          }
          dispatch({
            roles: decoded.roles,
            isAdmin: decoded.roles.filter(i => i.name == 'admin')?.length > 0 ? true : false,
            signedIn: true,
            email: decoded.email_address,
            is_custodial: decoded.is_custodial,
            id: decoded.id,
            access_token: jsonRes.access_token,
            issuer: decoded.issuer
          });
          setLoading(false);
          setOtp("");
          if (decoded.issuer) {
            router.push("/issuer");
            return
          } else {
            router.push("/account/");
          }
        } else {
          toast.error(jsonRes.message);
          setLoading(false);
          setError(jsonRes.message);
        }
      } catch (e) {
        toast.error("Something went wrong.");
        setError("Something went wrong.");
        setLoading(false);
      }
    }
  };
  const resendOtp = async () => {
    setLoading(true);
    setError("");
    try {
      const resp = await fetch(`${BASE_URL}v1/public/user/request-otp`, {
        method: "POST",
        body: JSON.stringify({
          email: router.query?.email,
          exp: router.query?.poet,
        }),
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.NEXT_PUBLIC_POET_API_KEY,
        },
      });
      const jsonRes = await resp.json();
      if (jsonRes.success) {
        toast.success("Passcode resent successfully!");
        setItemActive(1);
        intervalHanler();
        setLoading(false);
      } else {
        setLoading(false);
        setError(jsonRes.message);
      }
    } catch (e) {
      setError("Something went wrong.");
      setLoading(false);
    }
  };
  return (
    <>
      <form className="auth-form" onSubmit={verifyHandler}>
        <PasscodeInput value={otp} valueLength={6} onChange={onChange} />
        <button
          // htmlType="submit"
          disabled={loading}
          className="dark:bg-accent mt-4 dark:border-accent border-accent dark:hover:bg-accent-dark hover:bg-accent-dark text-white mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-accent py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
        >
          {loading ? (
            <span className="swiper-lazy-preloader btn-loader"></span>
          ) : (
            <span>Verify</span>
          )}
        </button>
        <button
          onClick={resendOtp}
          disabled={secSinceLastReq < 30 ? true : false}
          className="dark:bg-jacarta-600 mt-4 dark:border-jacarta-500 dark:hover:bg-jacarta-700 dark:text-white dark:hover:border-1 border-jacarta-100 hover:bg-jacarta-100 text-jacarta-600 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-jacarta-200 hover:text-jacarta-700 "
        >
          <span>
            Resend {secSinceLastReq < 30 && `(${30 - secSinceLastReq}s)`}
          </span>
        </button>
        <p className="text-white small-italic">{"(Please check your spam folder too)"}</p>
      </form>
    </>
  );
};

const Login = () => {
  const router = useRouter();
  const [itemActive, setItemActive] = useState('0');
  const tabItem = [
    {
      id: '0',
      text: "Sign In",
      // icon: "ETH",
    },
    {
      id: '1',
      text: "Verify",
      // icon: "torus",
    },
    // {
    //   id: 4,
    //   text: "Mobile Wallet",
    //   icon: "mbl-wallet",
    // },
  ];

  useEffect(() => {
    if (router.query?.poet) {
      localStorage.setItem('poet', JSON.stringify({ key: router.query?.poet, claimed: false }))
    }
  }, [router.query?.poet]);

  const [secSinceLastReq, setSecSinceLastReq] = useState(0);
  useEffect(() => {
    if (secSinceLastReq > 0) {
      setTimeout(() => {
        if (secSinceLastReq < 30) {
          intervalHanler();
        }
      }, 1000);
    }
  }, [secSinceLastReq]);

  const intervalHanler = () => {
    setSecSinceLastReq((preVal) => preVal + 1);
  };

  return (
    <PublicRoute>
      <div>
        <Meta title="Sign In - POET" />
        {/* <!-- Login --> */}
        <section className="bg-jacarta-800 relative h-screen">
          <div className="lg:flex lg:h-full">
            {/* <!-- Left --> */}
            <div className="relative animate-gradient-poet text-center lg:w-1/2">
              <img
                src="/images/login-banner-1.png"
                alt="login"
                className="absolute h-full w-full object-cover"
              />
              {/* <!-- Logo --> */}
              <Link href="/">
                <a className="relative inline-block py-36">
                  {/* <img
                    src="/images/logo_white.png"
                    className="inline-block max-h-7"
                    alt="POET | NFT Marketplace"
                  /> */}
                </a>
              </Link>
            </div>

            {/* <!-- Right --> */}
            <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                <img
                  src="/images/gradient_light.jpg"
                  alt="gradient"
                  className="h-full w-full"
                />
              </picture>

              <div className="w-full max-w-[25.625rem] text-center">
                <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white ">
                  Sign In
                </h1>
                <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                  {/* Choose one of the below options */}
                  Choose below option to Sign In or Register
                  {/* <a href="#" className="text-accent">
                  What is a wallet?
                </a> */}
                </p>

                {/* <!-- Tabs Nav --> */}
                <Tabs
                  className="tabs "
                  selectedIndex={Number(itemActive)}
                  onSelect={(e) => {
                    if (e == '0') {
                      setItemActive(e);
                    }
                    return;
                  }}
                >
                  <TabList className="no-scrollbar justify-center nav nav-tabs scrollbar-custom dark:border-jacarta-600 border-jacarta-100 mb-12 flex items-center justify-start overflow-x-auto overflow-y-hidden border-b pb-px md:justify-center">
                    {tabItem.map(({ id, text, icon }) => {
                      return (
                        <Tab className="nav-item" tabIndex={id} key={id}>
                          <button
                            className={
                              itemActive == id
                                ? "nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                                : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                            }
                          >
                            {/* <svg className="icon icon-ETH mr-1 mb-[2px] h-4 w-4 fill-current">
                            <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                          </svg> */}

                            <span className="font-display text-base font-medium">
                              {text}
                            </span>
                          </button>
                        </Tab>
                      );
                    })}
                  </TabList>

                  {/* <!-- SignIn --> */}
                  <TabPanel >
                    <div className="tab-pane fade show active">
                      <RequestOTPComp
                        setItemActive={(value) => setItemActive(value)}
                        setSecSinceLastReq={intervalHanler}
                      />
                      <p className="mb-4 py-4 px-8 text-center font-semibold dark:text-white">
                        Or
                      </p>

                      <Metamask_comp_login />

                      {/* <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <img
                        src="/images/wallets/torus_24.svg"
                        className="mr-2.5 inline-block h-6 w-6"
                        alt=""
                      />
                      <span>Torus</span>
                    </button>

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <img
                        src="/images/wallets/wallet_connect_24.svg"
                        className="mr-2.5 inline-block h-6 w-6"
                        alt=""
                      />
                      <span>Mobile Wallet</span>
                    </button>

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <span>Show more options</span>
                    </button> */}
                    </div>
                  </TabPanel>
                  {/* <!-- SignIn end --> */}

                  {/* <!-- Torus --> */}
                  <TabPanel
                  // selectedTabPanelClassName
                  >
                    <div
                      className="tab-pane fade"
                      id="torus"
                      aria-labelledby="torus-tab"
                    >
                      {itemActive == "1" && (
                        <Passcode
                          setItemActive={(val) => {
                            setItemActive(val);
                          }}
                          intervalHanler={() => {
                            setSecSinceLastReq(0);
                            intervalHanler();
                          }}
                          secSinceLastReq={secSinceLastReq}
                        />
                      )}
                      {/* <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <img
                        src="/images/wallets/torus_24.svg"
                        className="mr-2.5 inline-block h-6 w-6"
                        alt=""
                      />
                      <span>Torus</span>
                    </button>

                    <Metamask_comp_login />

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <img
                        src="/images/wallets/wallet_connect_24.svg"
                        className="mr-2.5 inline-block h-6 w-6"
                        alt=""
                      />
                      <span>Mobile Wallet</span>
                    </button>

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <span>Show more options</span>
                    </button> */}
                    </div>
                  </TabPanel>

                  {/* <!-- Torus end --> */}

                  {/* <!-- Wallet Connect --> */}
                  {/* <TabPanel>
                  <div className="tab-pane fade">
                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <img
                        src="/images/wallets/wallet_connect_24.svg"
                        className="mr-2.5 inline-block h-6 w-6"
                        alt=""
                      />
                      <span>Mobile Wallet</span>
                    </button>

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <img
                        src="/images/wallets/torus_24.svg"
                        className="mr-2.5 inline-block h-6 w-6"
                        alt=""
                      />
                      <span>Torus</span>
                    </button>

                    <Metamask_comp_login />

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-lg border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <span>Show more options</span>
                    </button>
                  </div>
                </TabPanel> */}
                  {/* <!-- Wallet Connect --> */}
                </Tabs>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end login --> */}
      </div>
    </PublicRoute>
  );
};

export default Login;
