import React, { useEffect } from "react";
import { useAppState } from "./AppContext";
import { useRouter } from 'next/router';

const PublicRoute = ({ children }) => {
  const state = useAppState();
  const router = useRouter();

  useEffect(() => {
    if (state.signedIn && !state.loading) {
      let page = state.issuer ? 'issuer' : 'account'
      if (state.issuer) {
        router.push(`/${page}/`);
        return
      }
      if (router.pathname == '/' && router.query.poet) {
        router.push(`/${page}?poet=` + router.query.poet);
        return
      }
      router.push(`/${page}/`);
    }
  }, [state.signedIn, state.loading]);

  if (state.loading) {
    return <div className="poet-overlay">
      <div className="poet-overlay__inner">
        <div className="poet-overlay__content">
          <span className="swiper-lazy-preloader"></span>
        </div>
      </div>
    </div>;
  } else {
    return <>{children}</>;
  }
};
export default PublicRoute;
